@font-face {
	font-family: Montserrat;
	src: url(../public/static/montserrat-regular.ttf) format("truetype");
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: Montserrat;
	src: url(../public/static/montserrat-bold.ttf) format("truetype");
	font-style: normal;
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: Montserrat;
	src: url(../public/static/montserrat-thin.ttf) format("truetype");
	font-style: normal;
	font-weight: 100;
	font-display: swap;
}

@font-face {
	font-family: Montserrat;
	src: url(../public/static/montserrat-light.ttf) format("truetype");
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

body {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #090c1c;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  min-height: 900px;
}

.landing {
  width: 100%;
  height: 100%;
  background-image: url("/public/static/bg-landing.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.landing > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.scuba-steve {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 90%;
  width: 50%;
}

.scuba-steve > img {
  height: 100%;
  width: auto;
}

.expand:hover {
  transform: scale(1.1);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.2rem 0rem;
  margin-right: 2rem;
}

.present {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
}

.company-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  padding: 0.5rem;
}

.company-logo {
  width: auto;
  height: 2.5rem;
}

.company-logos > p {
  display: block;
  font-weight: 400;
  font-size: 1.5rem;
  color: #ddd;
  margin: 0rem 1.5rem;
}

.present > p {
  display: block;
  text-align: center;
  color: #ddd;
  font-size: 1.5rem;
  margin: 2rem 0.3rem 0rem 0.3rem;
  font-weight: 300;
}

.hydro-logo-and-tagline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hydro-logo-and-tagline > img {
  display: flex;
  height: 275px;
  margin: -1rem 0rem;
}

.tagline, .description {
  display: block;
  text-align: center;
  color: #ddd;
  font-weight: 300;
  text-shadow: 1px 1px black;
}

.tagline {
  font-size: 2.1rem;
  margin-bottom: 1rem;
  letter-spacing: 2.9px;
}

.description {
  font-size: 1.1rem;
  width: 541px;
  margin-bottom: 1rem;
  letter-spacing: 0.2px;
}

.cta-and-socials {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cta {
  border-radius: 9px;
  cursor: pointer;
  border: 1px solid #353434;
  padding: 1rem;
  margin: 0 0 2rem 0;
  letter-spacing: 0.15rem;
  font-size: 1.5rem;
  font-weight: bold;
  width: 220px;
}

.cta > a {
  text-decoration: none;
  color: #353434;
}

.stake.cta {
  margin: 1.5rem 0rem 2rem 0rem;
}

.yellow {
  background-color: #F7CF81;
}

.blue {
  background-color: #81b5f7;
}

.social-media-group {
  display: block;
  align-items: center;
  justify-content: center;
}

.social-media-group > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 220px;
}

.social-media-icon.first {
  margin-left: 0;
}

.social-media-icon.last {
  margin-right: 0;
}

.social-media-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0rem 1.1rem 1rem;
}

@media only screen and (max-width: 1850px) {
  .hydro-logo-and-tagline > img {
    height: 250px;
  }

  .tagline {
    font-size: 1.9rem;
    letter-spacing: 2.6px;
  }

  .description {
    width: 489px;
    font-size: 1.02rem;
    letter-spacing: 0px;
  }
}

@media only screen and (max-width: 1750px) {

  .container {
    min-height: 700px;
  }

  .company-logo {
    height: 2rem;
  }

  .company-logos > p {
    font-size: 1rem;
    margin: 0rem 1rem;
  }

  .present {
    margin-bottom: 1rem;
  }

  .present > p {
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  .hydro-logo-and-tagline > img {
    margin: 0 0 -1rem 0;
    height: 225px;
  }

  .tagline {
    letter-spacing: 1.7px;
    font-size: 1.8rem;
  }

  .description {
    width: 400px;
    letter-spacing: 1.5px;
  }

  .stake.cta{
      margin: 1rem 0rem 1.25rem 0rem;
  }

  .cta {
    margin: 0 0 1.25rem 0;
    letter-spacing: 0.15rem;
    font-size: 1.2rem;
    width: 195px;
  }

  .social-media-group > div {
    width: 194px;
  }

  .social-media-icon {
    height: 2rem;
    width: 2rem;
    margin: 0rem 1.25rem 1rem;
  }
}

@media only screen and (max-width: 1550px) {
  .hydro-logo-and-tagline > img {
    height: 200px;
  }

  .tagline {
    font-size: 1.6rem;
    letter-spacing: 1.5px;
  }

  .description {
    width: 394px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1120px) {
  .scuba-steve {
    height: auto;
    width: 50%;
  }

  .scuba-steve > img {
    height: fit-content;
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {

  .container {
    min-height: 700px;
    overflow-y: hidden;
  }

  .landing > div {
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-color: rgba(19, 30, 52, 0.6);
  }

  .scuba-steve {
    display: none;
  }

  .scuba-steve > img {
    height: 100%;
    width: auto;
  }

  .content {
    margin: 0rem;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
  }

  .company-logo {
    height: 2rem;
  }

  .company-logos {
    margin-bottom: 0.5rem;
  }

  .company-logos > p {
    font-size: 1rem;
    margin: 0rem 1.5rem;
  }

  .present {
    padding: 0.5rem;
  }

  .present > p {
    font-size: 1rem;
    margin: 0rem;
  }

  .hydro-logo-and-tagline {
    height: 70%;
    justify-content: flex-end;
  }

  .hydro-logo-and-tagline > img {
    width: 100%;
    height: 100%;
    margin: -1rem 0rem;
    max-width: 420px;
    max-height: 186px;
  }

  .tagline {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    letter-spacing: 2.9px;
  }

  .description {
    font-size: 0.9rem;
    width: 364px;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  .stake.cta {
      margin: 2.5rem 0 1.5rem;
  }

  .cta {
    padding: 0.75rem 1.5rem;
    margin: 0 0 1.5rem 0;
    font-size: 1rem;
    min-width: 185px;
  }

  .social-media-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .social-media-group > div {
    justify-content: center;
    width: auto;
  }

  .social-media-icon.first {
    margin: 0rem 0.5rem;
  }

  .social-media-icon.last {
    margin: 0rem 0.5rem;
  }

  .social-media-icon {
    height: 2rem;
    width: 2rem;
    margin: 0rem 0.5rem;
  }
}

@media only screen and (max-width: 500px) {

  .hydro-logo-and-tagline > img {
    height: 150px;
    width: 375px;
    margin: -0.5rem 0rem;
  }

  .tagline {
    font-size: 1.22rem;
    letter-spacing: 1.8px;
  }

  .description {
    width: 325px;
    font-size: 0.8rem;
  }
}